import { compareProductPrice, getCookie, getData, sortManus } from './utils';
import { message } from 'antd';
import { REHYDRATE } from 'reduxjs-toolkit-persist';

const ADD_TO_CART = 'ADD_TO_CART';
const ADD_TO_CART_FAILED = 'ADD_TO_CART_FAILED';
const UPDATE_WIDTH = 'UPDATE_WIDTH';
const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';
const GET_MANUS_SUCCESS = 'GET_MANUS_SUCCESS';
const GET_WISHLIST_SUCCESS = 'GET_WISHLIST_SUCCESS';
const GET_ALL_WISHLISTS_SUCCESS = 'GET_ALL_WISHLISTS_SUCCESS';
const GET_CART_SUCCESS = 'GET_CART_SUCCESS';
const GET_COMPARISON_SUCCESS = 'GET_COMPARISON_SUCCESS';
const SHOW_MENU_SPINNER = 'SHOW_MENU_SPINNER';
const CHANGE_PRODLIST_SCROLL = 'CHANGE_PRODLIST_SCROLL';
const SET_SELECTED_CATEGORY_SUCCESS = 'SET_SELECTED_CATEGORY_SUCCESS';
const CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS';
const GET_ADDRESSES_SUCCESS = 'GET_ADDRESSES_SUCCESS';
const GET_LAST_ORDERS_SUCCESS = 'GET_LAST_ORDERS_SUCCESS';
const GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS';
const GET_CURRENT_CUSTOMER_SUCCESS = 'GET_CURRENT_CUSTOMER_SUCCESS';
const GET_OFFERS_SUCCESS = 'GET_OFFERS_SUCCESS';
const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
const OPTIMISTIC_CART_REMOVAL = 'OPTIMISTIC_CART_REMOVAL';

const addToCartFailed = () => {
    return {
        type: ADD_TO_CART_FAILED,
    };
};

const getCategoriesSuccess = (categories, activeMenu) => {
    return {
        type: GET_CATEGORIES_SUCCESS,
        categories,
        activeMenu,
    };
};

const setSelectedCategorySuccess = (selectedCategory) => {
    return {
        type: SET_SELECTED_CATEGORY_SUCCESS,
        selectedCategory,
    };
};

const getManusSuccess = (manus) => {
    return {
        type: GET_MANUS_SUCCESS,
        manus,
    };
};

const fetchWishListSuccess = (wishList) => {
    return {
        type: GET_WISHLIST_SUCCESS,
        wishList,
    };
};

const fetchAllWishListsSuccess = (wishLists) => {
    return {
        type: GET_ALL_WISHLISTS_SUCCESS,
        wishLists,
    };
};

const fetchCartSuccess = (cart) => {
    return {
        type: GET_CART_SUCCESS,
        cart,
    };
};

const fetchComparisonSuccess = (comparison) => {
    return {
        type: GET_COMPARISON_SUCCESS,
        comparison,
    };
};

const createOrderSuccess = (checkoutOrder) => {
    return {
        type: CREATE_ORDER_SUCCESS,
        checkoutOrder,
    };
};

const getAddressesSuccess = (addresses) => {
    return {
        type: GET_ADDRESSES_SUCCESS,
        addresses,
    };
};

const getOrdersSuccess = (ordersSearch) => {
    ordersSearch.orders.forEach((order) => {
        if (order.salesType !== 'FV') order.orderProducts.sort(compareProductPrice);
    });
    return {
        type: GET_ORDERS_SUCCESS,
        ordersSearch,
    };
};

const getLastOrdersSuccess = (lastOrdersSearch) => {
    return {
        type: GET_LAST_ORDERS_SUCCESS,
        lastOrdersSearch,
    };
};

const getCurrentCustomerSuccess = (currentCustomer) => {
    return {
        type: GET_CURRENT_CUSTOMER_SUCCESS,
        currentCustomer,
    };
};

const getOffersSuccess = (offers, numberOfOffers) => {
    return {
        type: GET_OFFERS_SUCCESS,
        offers,
        offerCount: numberOfOffers,
    };
};

const getProductsSuccess = (productsContent, body, url, customerId) => {
    productsContent.url = url;
    productsContent.body = body;
    productsContent.customerId = customerId;
    return {
        type: GET_PRODUCTS_SUCCESS,
        productsContent,
    };
};

export const getProducts = (body, url) => async (dispatch, getState) => {
    const { customerId } = getState();
    return (async () => {
        let content = await getData('FetchProducts', body);
        if (content) {
            if (content.currentTagGroups) {
                content.currentTagGroups.forEach(function (oneTagGroup) {
                    oneTagGroup.tags.sort(function (a, b) {
                        return a.sortOrder - b.sortOrder;
                    });
                });
            }
            dispatch(getProductsSuccess(content, body, url, customerId));
            dispatch(setSelectedCategorySuccess(content.selectedCategory));
        }
    })();
};

export const getOffers = (onlyCount) => async (dispatch) => {
    return (async () => {
        try {
            let content = await getData('GetOffers', { onlyCount: onlyCount });
            if (content) {
                dispatch(getOffersSuccess(content.offers, content.numberOfOffers));
            }
        } catch (err) {
            console.log(err);
        }
    })();
};

export const getCurrentCustomer = (getTags) => async (dispatch) => {
    return (async () => {
        try {
            let response = await getData('FetchCurrentCustomer', { getTags: getTags });
            if (response.customer) {
                dispatch(getCurrentCustomerSuccess(response.customer));
            }
        } catch (err) {
            console.log(err);
        }
    })();
};

export const getLastOrders = (body) => async (dispatch) => {
    return (async () => {
        try {
            let response = await getData('SearchForOrders', body);
            if (!response.orders) {
                response.orders = [];
            }
            dispatch(getLastOrdersSuccess(response));
        } catch (err) {
            console.log(err);
        }
    })();
};

export const getOrders = (body, multipleOrders) => async (dispatch) => {
    return (async () => {
        let url = 'SearchForOrders';
        if (multipleOrders) {
            url = process.env.REACT_APP_ORDERS_ENDPOINT;
        } else if (body.orderNumber) url = 'GetSalesOrder';
        try {
            let response = await getData(url, body);
            if (!response.orders) {
                response.orders = [];
            }
            dispatch(getOrdersSuccess(response));
        } catch (err) {
            console.log(err);
        }
    })();
};

export const getCustomerAddresses = (onlyPrimary) => async (dispatch) => {
    return (async () => {
        try {
            let response = await getData('GetAddresses', { onlyPrimary: onlyPrimary });
            if (response.addresses) {
                dispatch(getAddressesSuccess(response.addresses));
            }
        } catch (err) {
            console.log(err);
        }
    })();
};

export const createOrderFromBasket = (body) => async (dispatch) => {
    return (async () => {
        try {
            body.xsrf_token = window.xsrfToken;
            let checkoutOrder = await getData('OnePageRefresh', body).catch((error) => {
                if (error === 401) window.location.replace('LogIn');
            });
            if (checkoutOrder) {
                dispatch(createOrderSuccess(checkoutOrder));
            }
        } catch (err) {
            console.log(err);
        }
    })();
};

export const addToCart =
    (prodId, quantity, e, name, price, keepCartClosed, wishListId, wishListItemId) =>
    async (dispatch) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        return (async () => {
            try {
                if (name) {
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        event: 'addToCart',
                        ecommerce: {
                            currencyCode: 'USD',
                            add: {
                                products: [
                                    {
                                        name: name,
                                        id: prodId,
                                        price: price,
                                        quantity: quantity,
                                    },
                                ],
                            },
                        },
                    });
                }
                let body = {
                    prodId: prodId,
                    quantity: quantity,
                    // wishListId: wishListId,
                    // wishListItemId: wishListItemId,
                };
                await getData('AddToCartFromProdId', body);
                dispatch(fetchCart(keepCartClosed));
            } catch (err) {
                console.log(err);
                dispatch(addToCartFailed());
            }
        })();
    };

export const removeFromCart = (e, prodId, basketId) => async (dispatch) => {
    if (e) {
        e.preventDefault();
        e.stopPropagation();
    }
    return (async () => {
        try {
            dispatch({
                type: OPTIMISTIC_CART_REMOVAL,
                basketId,
            });
            let body = {
                basket: {
                    basketId: basketId,
                    prodId: prodId,
                },
            };
            await getData('EditBasket', body);
            dispatch(fetchCart(true));
        } catch (err) {
            console.log(err);
            dispatch(addToCartFailed());
        }
    })();
};

export const updateCart = (e, productId, basketId, quantity) => async (dispatch) => {
    if (e) {
        e.preventDefault();
        e.stopPropagation();
    }

    if (quantity === 0) {
        dispatch(removeFromCart(e, productId, basketId));
    } else {
        return (async () => {
            try {
                let body = {
                    basket: {
                        basketId: basketId,
                        quantity: quantity,
                        prodId: productId,
                    },
                };
                await getData('EditBasket', body);
                dispatch(fetchCart(true));
            } catch (err) {
                console.log(err);
                dispatch(addToCartFailed());
            }
        })();
    }
};

export const getCategories = () => async (dispatch) => {
    return (async () => {
        try {
            let content = await getData('FetchCategories', {});
            if (content) {
                dispatch(getCategoriesSuccess(content, 'productTree'));
            }
        } catch (err) {
            console.log(err);
            //TODO:
            dispatch(addToCartFailed());
        }
    })();
};

export const getManus = (manuSearch) => async (dispatch) => {
    return (async () => {
        try {
            let content = await getData('konakartjson', manuSearch);
            if (content.r && content.r.length > 0) {
                let manus = sortManus(content.r);
                dispatch(getManusSuccess(manus));
            }
        } catch (err) {
            console.log(err);
            //TODO:
            dispatch(addToCartFailed());
        }
    })();
};

export const fetchWishList = () => async (dispatch) => {
    await (async () => {
        try {
            let content = await getData('FetchWishList', {});
            let wishList = content.wishList;
            let wishListProductsIds = [];
            if (wishList && wishList.wishListItems != null) {
                wishList.wishListItems.forEach(function (oneItem) {
                    wishListProductsIds.push(oneItem.prodId);
                });
            }
            dispatch(
                fetchWishListSuccess({
                    wishListItems: wishList.wishListItems == null ? [] : wishList.wishListItems,
                    numberOfItems: wishList.numberOfItems,
                    formattedTotal: wishList.formattedTotal,
                    productIds: wishListProductsIds,
                })
            );
        } catch (err) {
            console.log(err);
        }
    })();
};

export const fetchAllWishLists = (forceRefresh) => async (dispatch) => {
    await (async () => {
        try {
            let content = await getData('FetchAllWishLists', { forceRefresh: forceRefresh });
            dispatch(fetchAllWishListsSuccess(content.wishLists));
        } catch (err) {
            console.log(err);
        }
    })();
};

export const fetchCart = (keepCartClosed) => async (dispatch) => {
    await (async () => {
        let content = await getData('FetchCart', {});
        dispatch(
            fetchCartSuccess({
                products: content.basketItems == null ? [] : content.basketItems,
                numberOfItems: content.numberOfItems,
                formattedTotal: content.formattedTotal,
                total: content.total,
                keepCartClosed: keepCartClosed,
                bagProduct: content.bagProduct,
            })
        );
    })();
};

export const fetchComparison = () => async (dispatch) => {
    await (async () => {
        try {
            let content = await getData('GetProductsInComparison', {});
            let comparisonProductsIds = [];
            if (content.comparisonItems != null) {
                content.comparisonItems.forEach(function (oneItem) {
                    comparisonProductsIds.push(oneItem.prodId);
                });
            }
            dispatch(
                fetchComparisonSuccess({
                    products: content.comparisonItems == null ? [] : content.comparisonItems,
                    numberOfItems: content.numberOfItems,
                    formattedTotal: content.total,
                    productIds: comparisonProductsIds,
                })
            );
        } catch (err) {
            console.log(err);
        }
    })();
};

export const addOrRemoveFromWishList =
    (e, prodId, basketIdToRemove, wishListId) => async (dispatch) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        await (async () => {
            try {
                //AddToWishListFromProdId has been modified so it removes the item if it exists
                let content = await getData('AddToWishListFromProdId', {
                    prodId: prodId,
                    basketIdToRemove: basketIdToRemove,
                    wishListId: wishListId,
                });
                if (!wishListId) {
                    let wishListProductsIds = [];
                    content.wishListItems.forEach(function (oneItem) {
                        wishListProductsIds.push(oneItem.prodId);
                    });
                    dispatch(
                        fetchWishListSuccess({
                            wishListItems:
                                content.wishListItems == null ? [] : content.wishListItems,
                            numberOfItems: content.numberOfItems,
                            formattedTotal: content.wishListTotal,
                            productIds: wishListProductsIds,
                        })
                    );
                } else {
                    dispatch(fetchAllWishLists(true));
                }
                if (basketIdToRemove) dispatch(fetchCart(true));
            } catch (err) {
                console.log(err);
            }
        })();
    };

export const addOrRemoveFromComparison = (e, prodId, removeAll) => async (dispatch) => {
    e.preventDefault();
    e.stopPropagation();
    await (async () => {
        try {
            let content;
            content = await getData('AddOrRemoveProductFromComparison', {
                prodId: prodId,
                removeAll: removeAll,
            });
            if (content.errorMsg === 'Too many products') {
                message.error(
                    'Ekki er hægt að bera saman fleiri en 4 vörur í einu. Vinsamlegast fjarlægðu vörur úr samanburði til að halda áfram. '
                );
            }
            let comparisonProductsIds = [];
            content.comparisonItems.forEach(function (oneItem) {
                comparisonProductsIds.push(oneItem.prodId);
            });
            dispatch(
                fetchComparisonSuccess({
                    products: content.comparisonItems == null ? [] : content.comparisonItems,
                    numberOfItems: content.numberOfItems,
                    formattedTotal: content.wishListTotal,
                    productIds: comparisonProductsIds,
                })
            );
        } catch (err) {
            console.log(err);
        }
    })();
    return false;
};

export const setSelectedCategory = (category) => async (dispatch) => {
    dispatch(setSelectedCategorySuccess(category));
};

export const updateWidth = (width) => {
    return {
        type: UPDATE_WIDTH,
        width,
    };
};

export const showMenuSpinner = () => {
    return {
        type: SHOW_MENU_SPINNER,
    };
};

export const productListScrolled = (scroll) => async (dispatch) => {
    return dispatch({
        type: CHANGE_PRODLIST_SCROLL,
        scroll,
    });
};

const initialState = {
    cart: {
        products: [],
    },
    browserWidth: window.innerWidth,
    categories: null,
    activeMenu: null,
    wishList: {
        productIds: [],
    },
    wishLists: [],
    comparison: {
        productIds: [],
    },
    menuSpinner: false,
    prodListScroll: 0,
    checkoutOrder: null,
    addresses: null,
    lastOrdersSearch: {
        orders: [],
    },
    ordersSearch: {
        orders: [],
    },
    currentCustomer: {},
    offers: [],
    offerCount: undefined,
    productsContent: {},
    isLoggedIn: getCookie('isLoggedIn') === 'true',
    customerId: getCookie('customerId'),
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case REHYDRATE:
            //Let items expiry in 15 mins
            if (
                action.payload?.productsContent &&
                action.payload.productsContent.timestamp + 1000 * 60 * 15 > new Date() &&
                action.payload.productsContent.customerId === state.customerId
            ) {
                action.payload.productsContent.prodListScroll = action.payload.prodListScroll;
                return {
                    ...state,
                    productsContent: action.payload.productsContent,
                };
            } else {
                return { ...state, productsContent: {} };
            }
        case ADD_TO_CART:
            return { ...state };
        case GET_CART_SUCCESS:
            return { ...state, cart: action.cart };
        case ADD_TO_CART_FAILED:
            return { ...state };
        case UPDATE_WIDTH:
            return { ...state, browserWidth: action.width };
        case GET_CATEGORIES_SUCCESS:
            return {
                ...state,
                categories: action.categories,
                activeMenu: action.activeMenu,
            };
        case SET_SELECTED_CATEGORY_SUCCESS:
            return { ...state, selectedCategory: action.selectedCategory };
        case GET_MANUS_SUCCESS:
            return { ...state, manus: action.manus };
        case SHOW_MENU_SPINNER:
            return { ...state, menuSpinner: true };
        case GET_WISHLIST_SUCCESS:
            return { ...state, wishList: action.wishList };
        case GET_COMPARISON_SUCCESS:
            return { ...state, comparison: action.comparison };
        case CHANGE_PRODLIST_SCROLL:
            return { ...state, prodListScroll: action.scroll };
        case CREATE_ORDER_SUCCESS:
            return { ...state, checkoutOrder: action.checkoutOrder };
        case GET_ADDRESSES_SUCCESS:
            return { ...state, addresses: action.addresses };
        case GET_LAST_ORDERS_SUCCESS:
            let lastOrdersSearch = action.lastOrdersSearch;
            lastOrdersSearch.orders = state.lastOrdersSearch.orders.concat(
                action.lastOrdersSearch.orders
            );
            return { ...state, lastOrdersSearch: lastOrdersSearch };
        case GET_ORDERS_SUCCESS:
            return { ...state, ordersSearch: action.ordersSearch };
        case GET_CURRENT_CUSTOMER_SUCCESS:
            return { ...state, currentCustomer: action.currentCustomer };
        case GET_OFFERS_SUCCESS:
            return { ...state, offers: action.offers, offerCount: action.offerCount };
        case GET_PRODUCTS_SUCCESS:
            if (
                state.productsContent.url === action.productsContent.url &&
                state.productsContent.page < action.productsContent.page
            ) {
                let prevProducts = state.productsContent.currentProducts;
                action.productsContent.currentProducts = prevProducts.concat(
                    action.productsContent.currentProducts
                );
                if (action.productsContent.prodsFound === 0) {
                    action.productsContent.prodsFound = state.productsContent.prodsFound;
                    action.productsContent.currentCats = state.productsContent.currentCats;
                    action.productsContent.currentManus = state.productsContent.currentManus;
                    action.productsContent.currentTagGroups =
                        state.productsContent.currentTagGroups;
                    action.productsContent.minPrice = state.productsContent.minPrice;
                    action.productsContent.maxPrice = state.productsContent.maxPrice;
                }
            }
            return { ...state, productsContent: action.productsContent };
        case GET_ALL_WISHLISTS_SUCCESS:
            return { ...state, wishLists: action.wishLists };
        case OPTIMISTIC_CART_REMOVAL:
            state.cart.products.filter((oneItem) => oneItem.basketId !== action.basketId);
            return { ...state };
        default:
            return state;
    }
};

export default reducer;

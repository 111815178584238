import { Button, Col, Row } from 'antd';
import Spinner from './components/Spinner';
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import 'dayjs/locale/is';
import VirtualList from 'rc-virtual-list';

const CUSTOMER_TAG_BOOLEAN_TYPE = 5;

export function getData(url, body) {
    return new Promise((resolve, reject) => {
        fetch('api/' + url, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Cache: 'no-cache',
            },
            credentials: 'same-origin',
            referrer: 'no-referrer',
            body: JSON.stringify(body),
        })
            .then((rawResponse) => {
                if (rawResponse.status !== 200) {
                    reject(rawResponse.status);
                }
                resolve(rawResponse.json());
            })
            .catch((error) => {
                console.error('Error during fetch:', error);
            });
    });
}

export function getLettkaupInfo(months, price) {
    return getData('GetLettkaupInfo', { price: price, months: months });
}

export function isProductNotification(prodId) {
    return getData('CheckNotification', { prodId: prodId });
}

export function addProductNotification(prodId) {
    return getData('SetNotification', { prodId: prodId });
}

export function removeProductNotification(prodId) {
    return getData('ResetNotification', { prodId: prodId });
}

export function fetchProducts(url, body, customerId) {
    (async () => {
        if (customerId) {
            body.customerId = customerId;
        }
        let content = await getData(url, body);
        if (url === 'konakartjson') {
            if (content.r && content.r.productArray && content.r.productArray.length > 0)
                this.setState({ products: content.r.productArray });
            else if (content.r && content.r.length > 0) this.setState({ products: content.r });
            else this.setState({ loading: false });
        } else {
            if (content.totalNumProducts !== 0) {
                this.setState({
                    products:
                        url === 'FetchProducts' ? content.currentProducts : content.productArray,
                    totalNumProducts: content.totalNumProducts,
                    searchKey: content.searchKey,
                    title: content.title,
                    showCount:
                        content.showCount === undefined ? this.state.showCount : content.showCount,
                    loading: false,
                });
            } else {
                this.setState({ loading: false });
            }
        }
    })();
}

export function getImageSize(imgArray, size) {
    let returnArray = [];
    if (imgArray) {
        for (let i = 0; i < imgArray.length; i++) {
            let oneImg = imgArray[i];
            if (oneImg.includes(size)) {
                returnArray.push(oneImg);
            }
        }
    }
    return returnArray;
}

export function changeImageSize(image, imageSize, newSize) {
    if (image) {
        return image.replace(imageSize, newSize).replace('_orig', '_' + newSize);
    }
    return image;
}

export function addDecimalAndCurrency(number, omitCurrency) {
    let stringNumber = Math.round(number)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    if (!omitCurrency) stringNumber += ' kr.';
    return stringNumber;
}

export function equal(arr1, arr2) {
    if (arr1 == null || arr2 == null) return arr1 === arr2;
    if (arr1.length !== arr2.length) return false;
    for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) return false;
    }
    return true;
}

export function formatNumber(number, omitTaxText, omitCurrency) {
    if (number == null || isNaN(number)) return null;
    let string = addDecimalAndCurrency(number, omitCurrency);
    if (!window.displayPriceWithTax && !omitTaxText) {
        string += ' án vsk.';
    }

    return string;
}

export function getPixels(number) {
    return number + 'px';
}

export function openProductCard(e) {
    e.preventDefault();
    e.stopPropagation();
    let url = '/SelectProd?prodId=' + this.props.id;
    if (e.ctrlKey || e.metaKey) {
        window.open(url, '_blank');
    } else {
        window.location = url;
    }
    return false;
}

export function createRows(cards, colSpan, numCols, gutter) {
    let rows = [];
    let colsForOneRow = [];
    while (cards.length > 0) {
        let tile = cards.shift();
        colsForOneRow.push(<Col span={colSpan}>{tile}</Col>);
        if (colsForOneRow.length === numCols) {
            rows.push(
                <Row style={{ marginBottom: gutter }} gutter={gutter}>
                    {colsForOneRow}
                </Row>
            );
            colsForOneRow = [];
        }
    }
    if (colsForOneRow.length > 0) {
        rows.push(<Row gutter={gutter}>{colsForOneRow}</Row>);
    }
    return rows;
}

export function compareManus(a, b) {
    let aSort = parseInt(process.env.REACT_APP_STORE === 'tolvutek' ? a.custom5 : a.custom3);
    let bSort = parseInt(process.env.REACT_APP_STORE === 'tolvutek' ? b.custom5 : b.custom3);
    if (aSort < bSort) {
        return -1;
    }
    if (aSort > bSort) {
        return 1;
    }
    return 0;
}

export function compareManusByName(a, b) {
    let aSort = a.name;
    let bSort = b.name;
    if (aSort < bSort) {
        return -1;
    }
    if (aSort > bSort) {
        return 1;
    }
    return 0;
}

export function sortManus(manus) {
    let unsorted = [];
    let sorted = [];
    for (let i = 0; i < manus.length; i++) {
        let manu = manus[i];
        let sortOrder;
        if (process.env.REACT_APP_STORE === 'tolvutek') {
            sortOrder = manu.custom5;
        } else {
            sortOrder = manu.custom3;
        }
        if (!sortOrder) {
            unsorted.push(manu);
        } else {
            sorted.push(manu);
        }
    }
    //Server side sorting returns 10 before 2
    //Results are sorted again
    sorted.sort(compareManus);
    return sorted.concat(unsorted);
}

export function sortManusMobile(manus) {
    return manus.sort(compareManusByName);
}

export function afterChange(index) {
    this.setState({ index: index });
}

export function getParamsFromUrl(url) {
    let regexParam = /[?&]([^=#]+)=([^&#]*)/g,
        match = regexParam.exec(url),
        params = {};
    while (match != null) {
        params[match[1]] = match[2];
        match = regexParam.exec(url);
    }
    return params;
}

export function updateURLParameter(url, param, paramVal) {
    let TheAnchor = null;
    let newAdditionalURL = '';
    let tempArray = url.split('?');
    let baseURL = tempArray[0];
    let additionalURL = tempArray[1];
    let temp = '';

    if (additionalURL) {
        let tmpAnchor = additionalURL.split('#');
        let TheParams = tmpAnchor[0];
        TheAnchor = tmpAnchor[1];
        if (TheAnchor) additionalURL = TheParams;

        tempArray = additionalURL.split('&');

        for (let i = 0; i < tempArray.length; i++) {
            if (tempArray[i].split('=')[0] !== param) {
                newAdditionalURL += temp + tempArray[i];
                temp = '&';
            }
        }
    } else {
        let tmpAnchor = baseURL.split('#');
        let TheParams = tmpAnchor[0];
        TheAnchor = tmpAnchor[1];

        if (TheParams) baseURL = TheParams;
    }

    if (TheAnchor) paramVal += '#' + TheAnchor;
    if (paramVal !== '') {
        let rows_txt = temp + '' + param + '=' + paramVal;
        return baseURL + '?' + newAdditionalURL + rows_txt;
    } else return baseURL + '?' + newAdditionalURL;
}

export function getUpsellProductsInCartForProduct(productsInCart, product) {
    let upsellProducts = [];
    for (let j = 0; j < productsInCart.length; j++) {
        let possibleUpsell = productsInCart[j];
        let productIdForUpsell = parseInt(possibleUpsell.custom4);
        if (productIdForUpsell) {
            //TODO: id is stored under prodId in basket, productId on orders
            let productId = product.productId ? product.productId : product.prodId;
            if (productIdForUpsell === productId) {
                if (!possibleUpsell.name) possibleUpsell.name = possibleUpsell.prodName;
                upsellProducts.push(possibleUpsell);
            }
        }
    }
    return upsellProducts;
}

export function createLiveChat() {
    window.HubSpotConversations.widget.open();
}

export function setDefaultImage(e) {
    e.target.onerror = null;
    e.target.src = window.imageBase + '/netverslun/myndvantar.jpg';
}

export function getWebPImagePath(imagePath) {
    return imagePath.replace('.jpg', '.webp').replace('.jpeg', '.png').replace('.png', '.webp');
}

export function getCartWithScrollbar(products) {
    return (
        <VirtualList
            className="inner-cart"
            data={[this.getCart(products)]}
            itemHeight={186}
            height={450}
            virtual={false}
        >
            {(item) => item}
        </VirtualList>
    );
}

export function getCartWithoutScrollbar(products) {
    return (
        <div className={'inner-cart'}>
            {products.length > 0 ? (
                this.getCart(products)
            ) : (
                <div id={'no-products'}>{this.noProductsMessage}</div>
            )}
        </div>
    );
}

export function getCustomerTagObject(tags) {
    let customerTags = {};
    for (let tag of tags) {
        let value = tag.value;
        if (tag.type === CUSTOMER_TAG_BOOLEAN_TYPE) value = tag.value === 'true';
        customerTags[tag.name] = value;
    }
    return customerTags;
}

export function renderContent(content, buttonHref, contentOverrides) {
    if (!content) return <Spinner className="center-spinner" size={120} />;
    const imageBase = window.imageBase + '/content/';
    if (!buttonHref) buttonHref = content.clickUrl;
    if (contentOverrides) {
        for (const [key, value] of Object.entries(contentOverrides)) {
            content.description.content = content.description.content.replaceAll(
                '{{' + key + '}}',
                value
            );
        }
    }
    return (
        <div>
            <div id="content-text">
                <h1>{content.description.title}</h1>
                <p dangerouslySetInnerHTML={{ __html: content.description.content }} />
                {buttonHref && content.description.custom2 && (
                    <Button className="origo-button" href={buttonHref}>
                        {content.description.custom2}
                    </Button>
                )}
            </div>
            {content.description.name1 && content.description.name2 && (
                <picture>
                    <source
                        srcSet={`${imageBase + content.description.name2} 500w, 
                         ${imageBase + content.description.name1} 880w`}
                    />
                    <img
                        id="content-image"
                        alt={content.description.title}
                        src={imageBase + content.description.name1}
                    />
                </picture>
            )}
        </div>
    );
}

export function isOrderProcessed(status) {
    switch (status) {
        case 7:
        case 3:
        case 14:
        case 9:
        case 10:
        case 15:
        case 5:
        case 17:
        case 18:
        case 16:
        case 19:
        case 20:
        case 21:
            return true;
        default:
            return false;
    }
}

export async function fetchBlogs() {
    let blogsRes = await fetch('https://www.origo.is/api/blogg/netverslun?count=3&lang=is', {
        method: 'GET',
    }).then((rawResponse) => {
        return rawResponse.json();
    });

    let blogs = [];
    blogsRes?.forEach((oneBlog) => {
        oneBlog.url = 'https://origo.is' + oneBlog.url;
        blogs.push(oneBlog);
    });
    if (blogs.length > 0) this.setState({ blogs: blogs });
}

export function getCookie(name) {
    // Split cookie string and get all individual name=value pairs in an array
    const cookieArr = document.cookie.split(';');
    // Loop through the array elements
    for (const element of cookieArr) {
        const cookiePair = element.split('=');
        /* Removing whitespace at the beginning of the cookie name
        and compare it with the given string */
        if (name === cookiePair[0].trim()) {
            // Decode the cookie value and return
            return decodeURIComponent(cookiePair[1]);
        }
    }

    // Return null if not found
    return null;
}

export function compareProductPrice(op1, op2) {
    if (op1.price > op2.price) {
        return -1;
    }
    if (op1.price < op2.price) {
        return 1;
    }
    return 0;
}

export function shouldDisplayDateAvailable(date) {
    if (!date) return false;
    const now = getDayjs();
    const target = getDayjs(date);
    const diffMonths = target.diff(now, 'month');
    return !target.isBefore(now) && diffMonths < 3;
}

export function trimString(str) {
    if (!str) return '';
    return str.trim();
}

export function getDayjs(date) {
    dayjs.extend(updateLocale);
    dayjs.updateLocale('is', {
        monthsShort: [
            'Jan',
            'Feb',
            'Mars',
            'Apríl',
            'Maí',
            'Júní',
            'Júlí',
            'Ágúst',
            'Sept',
            'Okt',
            'Nóv',
            'Des',
        ],
    });
    dayjs.updateLocale('is', {
        months: [
            'Janúar',
            'Febrúar',
            'Mars',
            'Apríl',
            'Maí',
            'Júní',
            'Júlí',
            'Ágúst',
            'September',
            'Október',
            'Nóvember',
            'Desember',
        ],
    });
    dayjs.locale('is');
    return dayjs(date);
}

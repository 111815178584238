import React from 'react';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';

export default class ComponentWrapper extends React.Component {
    render() {
        return (
            <Provider store={this.props.store}>
                <ConfigProvider
                    theme={{
                        components: {
                            Button: {
                                defaultHoverBg: process.env.REACT_APP_PRIMARY_COLOR,
                                defaultActiveBg: process.env.REACT_APP_PRIMARY_COLOR,
                                colorPrimaryHover: process.env.REACT_APP_PRIMARY_COLOR,
                            },
                        },
                        token: {
                            fontFamily: 'TTNorms-Pro-Regular',
                            colorPrimary: process.env.REACT_APP_PRIMARY_COLOR,
                            colorLink: process.env.REACT_APP_PRIMARY_COLOR,
                            colorLinkHover: process.env.REACT_APP_PRIMARY_COLOR_HOVER,
                            fontSize: this.props.fontSize ? this.props.fontSize : 15,
                            colorText: '#0F1015',
                            motionEaseOut: 'ease-in',
                            borderRadius: 5,
                        },
                    }}
                >
                    {this.props.children}
                </ConfigProvider>
            </Provider>
        );
    }
}

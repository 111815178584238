import OrigoSpinner from './OrigoSpinner';
import { LoadingOutlined } from '@ant-design/icons';

const Spinner = (props) => {
    if (process.env.REACT_APP_STORE === 'store1') {
        let containerDimensions = props.size + 'px';
        let ballDimensions = props.size / 10 + 'px';
        return (
            <OrigoSpinner
                className={props.className}
                height={containerDimensions}
                width={containerDimensions}
                ballWidth={ballDimensions}
                ballHeight={ballDimensions}
            />
        );
    } else
        return (
            <div className={props.className}>
                <LoadingOutlined style={{ fontSize: props.size }} />
            </div>
        );
};

export default Spinner;

import 'preact/devtools';
import { render } from 'react-dom';
import loadable from '@loadable/component';
import configStore from './configStore';
import { PersistGate } from 'reduxjs-toolkit-persist/integration/react';
import Spinner from './components/Spinner';
import { getParamsFromUrl } from './utils';
import ComponentWrapper from './ComponentWrapper';
import './index.less';
import './css/kk-style.less';
import './css/origo.less';

const Header = loadable(
    () =>
        import(
            /* webpackChunkName: "Header" */ /* webpackPreload: true */ `${process.env.REACT_APP_HEADER_COMPONENT_PATH}`
        )
);

const FeaturedArea = loadable(
    () =>
        import(
            /* webpackChunkName: "FeaturedArea" */ /* webpackPreload: true */ './components/FeaturedArea'
        )
);

const path = window.location.pathname;
const { store, persistor } = configStore();

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line
    __webpack_public_path__ = 'http://localhost:3000/';
}
if (!window.admin) {
    render(
        <ComponentWrapper store={store}>
            <Header showOffers={true} store={store} />
        </ComponentWrapper>,
        document.getElementById('header')
    );

    if (window.appLocation !== 'comparison') {
        const ComparisonPopup = loadable(
            () => import(/* webpackChunkName: "ComparisonPopup" */ './components/ComparisonPopup')
        );

        render(
            <ComponentWrapper store={store}>
                <ComparisonPopup />
            </ComponentWrapper>,
            document.getElementById('comparison-popup-container')
        );
    }
}

switch (window.appLocation) {
    case 'mainPage':
        const ProductGallery = loadable(
            () =>
                import(
                    /* webpackChunkName: "ProductGallery" */ /* webpackPreload: true */ './components/ProductGallery'
                )
        );

        const featuredArea1Search = {
            search: {
                contentTypeId: 35,
                languageId: 9,
                retrieveDescriptions: true,
                enabled: true,
            },
        };

        const featuredArea2Search = {
            search: {
                contentTypeId: 36,
                languageId: 9,
                retrieveDescriptions: true,
                enabled: true,
            },
        };

        const topBannerSearch = {
            search: {
                contentTypeId: 1,
                languageId: 9,
                retrieveDescriptions: true,
                enabled: true,
            },
        };

        if (process.env.REACT_APP_STORE === 'tolvutek') {
            render(
                <ComponentWrapper store={store}>
                    <FeaturedArea
                        url={'GetKonakartContent'}
                        body={topBannerSearch}
                        slider={true}
                        store={store}
                    />
                </ComponentWrapper>,
                document.getElementById('top-banner')
            );

            render(
                <ComponentWrapper store={store}>
                    <ProductGallery
                        url={'/FetchRecommendedProducts'}
                        body={{ tag: 'PRODUCT_CAROUSEL_1' }}
                        store={store}
                    />
                </ComponentWrapper>,
                document.getElementById('products-carousel-1')
            );

            render(
                <ComponentWrapper store={store}>
                    <FeaturedArea
                        url={'GetKonakartContent'}
                        body={featuredArea1Search}
                        slider={true}
                        store={store}
                    />
                </ComponentWrapper>,
                document.getElementById('featured-area-1')
            );

            render(
                <ComponentWrapper store={store}>
                    <ProductGallery
                        url={'/FetchRecommendedProducts'}
                        body={{ tag: 'PRODUCT_CAROUSEL_2' }}
                        store={store}
                    />
                </ComponentWrapper>,
                document.getElementById('products-carousel-2')
            );

            render(
                <ComponentWrapper store={store}>
                    <FeaturedArea
                        url={'GetKonakartContent'}
                        body={featuredArea2Search}
                        slider={true}
                        store={store}
                    />
                </ComponentWrapper>,
                document.getElementById('featured-area-2')
            );
        } else {
            render(
                <ComponentWrapper store={store}>
                    <FeaturedArea
                        url={'GetKonakartContent'}
                        body={topBannerSearch}
                        slider={true}
                        store={store}
                    />
                </ComponentWrapper>,
                document.getElementById('top-banner')
            );

            render(
                <ComponentWrapper store={store}>
                    <ProductGallery
                        url={'/FetchRecommendedProducts'}
                        body={{ tag: 'PRODUCT_CAROUSEL_1' }}
                        store={store}
                    />
                </ComponentWrapper>,
                document.getElementById('products-carousel-1')
            );

            render(
                <ComponentWrapper store={store}>
                    <FeaturedArea
                        url={'GetKonakartContent'}
                        body={featuredArea1Search}
                        slider={true}
                        store={store}
                    />
                </ComponentWrapper>,
                document.getElementById('featured-area-1')
            );

            render(
                <ComponentWrapper store={store}>
                    <ProductGallery
                        url={'/FetchRecommendedProducts'}
                        body={{ tag: 'PRODUCT_CAROUSEL_2' }}
                        store={store}
                    />
                </ComponentWrapper>,
                document.getElementById('products-carousel-2')
            );

            render(
                <ComponentWrapper store={store}>
                    <FeaturedArea
                        url={'GetKonakartContent'}
                        body={featuredArea2Search}
                        slider={true}
                        store={store}
                    />
                </ComponentWrapper>,
                document.getElementById('featured-area-2')
            );
        }
        break;
    case 'productList':
        const ProductList = loadable(
            () =>
                import(
                    /* webpackChunkName: "ProductList" */ /* webpackPreload: true */ './pages/ProductList/ProductList'
                )
        );
        const parts = path.split('/');
        const name = parts[parts.length - 1];

        if (name) {
            const featuredAreaBody = {
                search: {
                    searchKey: name,
                    languageId: 9,
                    retrieveDescriptions: true,
                    enabled: true,
                },
            };
            render(
                <ComponentWrapper store={store}>
                    <FeaturedArea
                        url={'GetKonakartContent'}
                        body={featuredAreaBody}
                        noSpinner={true}
                        store={store}
                    />
                </ComponentWrapper>,
                document.getElementById('product-list-banner')
            );
        }
        render(
            <ComponentWrapper store={store}>
                <PersistGate loading={<Spinner />} persistor={persistor}>
                    <ProductList url={'/FetchProducts'} store={store} />
                </PersistGate>
            </ComponentWrapper>,
            document.getElementById('product-list')
        );
        break;

    case 'productPage':
        const ProductPage = loadable(
            () =>
                import(
                    /* webpackChunkName: "ProductPage" */ /* webpackPreload: true */ './pages/ProductPage/ProductPage'
                )
        );
        const regex = /_([\d]+)\.action/;
        let matches = regex.exec(path);
        let prodId = matches == null ? null : matches[1];
        render(
            <ComponentWrapper store={store}>
                <ProductPage
                    url={'/FetchProduct'}
                    body={{ prodId: prodId }}
                    prodId={prodId}
                    store={store}
                />
            </ComponentWrapper>,
            document.getElementById('product-page')
        );
        break;

    case 'voruskil':
        const Voruskil = loadable(
            () =>
                import(
                    /* webpackChunkName: "Voruskil" */ /* webpackPreload: true */ './pages/Voruskil/Voruskil'
                )
        );
        render(<Voruskil store={store} />, document.getElementById('voruskil-container'));
        break;

    case 'comparison':
        const Comparison = loadable(
            () =>
                import(
                    /* webpackChunkName: "Comparison" */ /* webpackPreload: true */ './pages/Comparison/Comparison'
                )
        );
        render(
            <ComponentWrapper store={store}>
                <Comparison store={store} />
            </ComponentWrapper>,
            document.getElementById('comparison-container')
        );
        break;

    case 'shoppingCart':
        const ShoppingCart = loadable(
            () =>
                import(
                    /* webpackChunkName: "ShoppingCart" */ /* webpackPreload: true */ './pages/ShoppingCart/ShoppingCart'
                )
        );
        render(
            <ComponentWrapper store={store}>
                <ShoppingCart store={store} />
            </ComponentWrapper>,
            document.getElementById('shopping-cart-container')
        );
        break;

    case 'checkout':
        const Checkout = loadable(
            () =>
                import(
                    /* webpackChunkName: "Checkout" */ /* webpackPreload: true */ './pages/Checkout/Checkout'
                )
        );
        render(
            <ComponentWrapper store={store}>
                <Checkout store={store} />
            </ComponentWrapper>,
            document.getElementById('checkout-container')
        );
        break;

    case 'login':
        const Login = loadable(
            () =>
                import(
                    /* webpackChunkName: "Login" */ /* webpackPreload: true */ './pages/Login/Login'
                )
        );
        render(
            <ComponentWrapper store={store}>
                <Login store={store} />
            </ComponentWrapper>,
            document.getElementById('login-container')
        );
        break;

    case 'registerCustomer':
        const RegisterCustomer = loadable(
            () =>
                import(
                    /* webpackChunkName: "RegisterCustomer" */ /* webpackPreload: true */ './pages/RegisterCustomer/RegisterCustomer'
                )
        );
        render(
            <ComponentWrapper store={store}>
                <RegisterCustomer store={store} />
            </ComponentWrapper>,
            document.getElementById('register-customer-container')
        );
        const featuredAreaBody = {
            search: {
                searchKey: 'nyskraning',
                languageId: 9,
                retrieveDescriptions: true,
                enabled: true,
            },
        };
        render(
            <ComponentWrapper store={store}>
                <FeaturedArea
                    url={'GetKonakartContent'}
                    body={featuredAreaBody}
                    noSpinner={true}
                    store={store}
                />
            </ComponentWrapper>,
            document.getElementById('register-customer-banner')
        );
        break;
    case 'endurnyttu':
        const Endurnyttu = loadable(
            () =>
                import(
                    /* webpackChunkName: "Endurnyttu" */ /* webpackPreload: true */ './pages/Endurnyttu/Endurnyttu'
                )
        );
        render(
            <Endurnyttu store={store} fontSize={18} />,
            document.getElementById('endurnyttu-container')
        );
        break;
    case 'my-account':
        const MyAccount = loadable(
            () =>
                import(
                    /* webpackChunkName: "MyAccount" */ /* webpackPreload: true */ './pages/MyAccount/MyAccount'
                )
        );
        render(
            <ComponentWrapper store={store} fontSize={18}>
                <MyAccount />
            </ComponentWrapper>,
            document.getElementById('my-account-container')
        );
        break;
    case 'edit-customer':
        const EditCustomer = loadable(
            () =>
                import(
                    /* webpackChunkName: "EditCustomer" */ /* webpackPreload: true */ './pages/EditCustomer/EditCustomer'
                )
        );
        render(
            <ComponentWrapper store={store}>
                <EditCustomer store={store} />
            </ComponentWrapper>,
            document.getElementById('edit-customer-container')
        );
        break;
    case 'order-details':
        const OrderDetails = loadable(
            () =>
                import(
                    /* webpackChunkName: "OrderDetails" */ /* webpackPreload: true */ './pages/OrderDetails/OrderDetails'
                )
        );
        render(
            <ComponentWrapper store={store} fontSize={18}>
                <OrderDetails store={store} />
            </ComponentWrapper>,
            document.getElementById('order-details-container')
        );
        break;
    case 'offers':
        const Offers = loadable(
            () =>
                import(
                    /* webpackChunkName: "Offers" */ /* webpackPreload: true */ './pages/Offers/Offers'
                )
        );
        render(
            <ComponentWrapper store={store}>
                <Offers store={store} offers={window.offers} admin={window.admin} />
            </ComponentWrapper>,
            document.getElementById('offers-container')
        );
        break;
    case 'checkout-finished':
        const CheckoutFinished = loadable(
            () =>
                import(
                    /* webpackChunkName: "Content" */ /* webpackPreload: true */ './pages/Content/Content'
                )
        );
        const url = window.location.href;
        const params = getParamsFromUrl(url);
        render(
            <CheckoutFinished
                contentTypeId={105}
                buttonHref={'ShowOrderDetails?orderId=' + params['orderId']}
            />,
            document.getElementById('checkout-finished-container')
        );
        break;
    case 'log-out':
        const LogOut = loadable(
            () =>
                import(
                    /* webpackChunkName: "Content" */ /* webpackPreload: true */ './pages/Content/Content'
                )
        );
        render(
            <LogOut contentTypeId={106} buttonHref="LogIn" />,
            document.getElementById('log-out-container')
        );
        break;
    case 'prismic-page':
        const Page = loadable(
            () =>
                import(
                    /* webpackChunkName: "PrismicPage" */ /* webpackPreload: true */ './pages/PrismicPage/PrismicPage'
                )
        );
        render(<Page />, document.getElementById('prismic-page-container'));
        break;
    case 'wishLists':
        const WishLists = loadable(
            () =>
                import(
                    /* webpackChunkName: "WishLists" */ /* webpackPreload: true */ './pages/WishLists/WishLists'
                )
        );
        render(
            <ComponentWrapper store={store}>
                <WishLists store={store} />
            </ComponentWrapper>,
            document.getElementById('wishlists-container')
        );
        const wishListParams = getParamsFromUrl(window.location.href);
        const wishListId = wishListParams['wishListId'];

        if (wishListId) {
            const featuredAreaWishLists = {
                search: {
                    searchKey: wishListId,
                    languageId: 9,
                    retrieveDescriptions: true,
                    enabled: true,
                },
            };
            render(
                <ComponentWrapper store={store}>
                    <FeaturedArea
                        url={'GetKonakartContent'}
                        body={featuredAreaWishLists}
                        noSpinner={true}
                        store={store}
                    />
                </ComponentWrapper>,
                document.getElementById('wishlists-banner')
            );
        }
        break;
    case 'content-page':
        const ContentPage = loadable(
            () =>
                import(
                    /* webpackChunkName: "Content" */ /* webpackPreload: true */ './pages/Content/Content'
                )
        );
        render(
            <ContentPage
                contentTypeId={window.contentTypeId}
                contentOverrides={window.contentOverrides}
            />,
            document.getElementById('content-container')
        );
        break;
    case 'address-book':
        const AddressBook = loadable(
            () =>
                import(
                    /* webpackChunkName: "AddressBook" */ /* webpackPreload: true */ './pages/AddressBook/AddressBook'
                )
        );
        render(
            <ComponentWrapper store={store}>
                <AddressBook />
            </ComponentWrapper>,
            document.getElementById('address-book-container')
        );
        break;
    case 'manufacturers':
        const Manufacturers = loadable(
            () =>
                import(
                    /* webpackChunkName: "Manufacturers" */ /* webpackPreload: true */ './pages/Manufacturers/Manufacturers'
                )
        );
        render(
            <ComponentWrapper store={store}>
                <Manufacturers />
            </ComponentWrapper>,
            document.getElementById('manufacturers-container')
        );
        break;
    default:
        break;
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();

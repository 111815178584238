import reducer from './reducer';
import { configureStore } from '@reduxjs/toolkit';
import {
    FLUSH,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
    REHYDRATE,
    persistReducer,
    persistStore,
} from 'reduxjs-toolkit-persist';
import storage from 'reduxjs-toolkit-persist/lib/storage';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['productsContent', 'prodListScroll'],
};

const persistedReducer = persistReducer(persistConfig, reducer);

function configStore() {
    const store = configureStore({
        reducer: persistedReducer,
        devTools: process.env.NODE_ENV !== 'production',
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: {
                    ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
                },
            }),
    });

    let persistor = persistStore(store);

    return { store, persistor };
}

export default configStore;
